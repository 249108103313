// https://stackoverflow.com/a/51790464/1124157

turboPersistScroll();

const persistScrollDataAttribute = "turbo-persist-scroll";
let scrollPosition = null;
let enabled = false;

function turboPersistScroll() {
  document.addEventListener("turbo:before-visit", cacheScrollPosition);
  document.addEventListener("turbo:load", setScrollPosition);
}

function cacheScrollPosition() {
  if (enabled) {
    scrollPosition = window.scrollY;
  } else {
    scrollPosition = null;
  }
  enabled = false;
}

function setScrollPosition() {
  const elements = document.querySelectorAll(
    `[data-${persistScrollDataAttribute}="true"]`
  );
  for (let i = 0; i < elements.length; i++) {
    elements[i].addEventListener("click", () => {
      enabled = true;
    });
  }

  if (scrollPosition) {
    window.scrollTo(0, scrollPosition);
  }
}
