# node_modules
require("sticky-table-headers")
require("magnific-popup")
`import Clipboard from "clipboard";`
require("datatables")

require("jquery-ui")
require("jquery-ui/ui/effect")
require("jquery-ui/ui/effects/effect-highlight")

# auto-vendored
# https://github.com/webpack-contrib/exports-loader
`import BestInPlaceEditor from "vendor/best_in_place/best_in_place"`
window.BestInPlaceEditor = BestInPlaceEditor
require("vendor/best_in_place/jquery.autosize")
require("vendor/best_in_place/best_in_place.jquery-ui")
require("vendor/best_in_place/jquery.purr")
require("vendor/best_in_place/best_in_place.purr")

# eigen code
require("active_admin/admin/orders")
require("active_admin/admin/return_items")
require("active_admin/admin/school_year")
require("active_admin/admin/swal")
require("active_admin/admin/winkel/deliveries")

document.addEventListener "turbo:load", ->
  $('.fixed_headers').stickyTableHeaders()

  # data inlezen uitgeverij => verwijder lege rijen en submit
  $(".check_with_import input.submit_supplier_data").click (e) ->
      e.preventDefault()
      $("input[data-kind='save_on_submit']:not(:checked)").parents("tr").remove()
      $(e.target).parent("form.supplier_data").submit()

  # algemene show/hide trigger
  $(".show_hide_trigger").on "click", (e)->
    e.preventDefault()
    el = $(e.target)
    trigger = el.prop("id")
    $(".#{trigger}_toggle").toggle()

  $('.image-popup').magnificPopup(type:'image')

  clipboard = new Clipboard('.clip')

  clipboard.on('success', (e) ->
    e.trigger.classList.add("copied")
  )

  # toon pakketnummer
  $("#show_collected_number").click (e) ->
    e.preventDefault()
    el = $(e.target)
    alert "Opgelet: dit pakket werd nog niet betaald. Geef het pakket enkel mee na de registratie van een betaling."
    el.hide()
    el.next().show()

  $("[data-prompt]").click (e) ->
    el = $(e.target)
    return false if el.hasClass("disabled")

    answer = el.data("answer") || "akkoord"

    question = "#{el.data("prompt")}\n\nTik '#{answer}' om te bevestigen."

    result = prompt(question)

    return false if result == null

    return answer.toUpperCase() == result.toUpperCase()

  $("table.data_table, .has_data_table table").DataTable(paging: false, ordering: false, language: { search: "Zoek: ", zeroRecords: "Niets gevonden", info: "Pagina _PAGE_ van _PAGES_", infoEmpty: "Geen resultaten", infoFiltered: "(gefilterd van _MAX_ lijnen)" })

  init_bulk_overview_copier = ->
    $("#overview [data-event='use_data']").click (e) ->
      e.preventDefault()

      th = $(this).closest("th")
      table = $("#overview table").DataTable()
      column = table.column(th)
      index = column.index()

      table.rows().every ->
        row = this
        $(row.nodes()).find("input.amount").val(row.data()[index])
  init_bulk_overview_copier()

  $(".check_column").change (e) ->
    group = $(this).data().group
    kind = $(this).data().kind
    checked = $(this).prop("checked")

    $("input[data-group='#{group}'][data-kind='#{kind}']").prop("checked", checked)

  $("#wrapper button, #wrapper .action_item > a, #wrapper input[type='submit']") \
    .click (e) ->
      that = this

      if $(that).hasClass("disabled")
        return false

      disable = (that) ->
        $(that).addClass("disabled")
        $(that).prop('disabled', true)

      enable = (that) ->
        $(that).removeClass("disabled")
        $(that).prop('disabled', false)

      setTimeout( ( -> disable(that) ), 1)
      setTimeout( ( -> enable(that) ), 5000)

  # algemeen systeem om previews te gebruiken
  # verwacht volgende structuur:
  #
  # .preview
  #   a.control "klik hier om meer te lezen"
  #
  #   .visible_preview (meteen getoond)
  #   .hidden_preview (getoond na klik)
  #
  $(".preview a.control").click (e) ->
    e.preventDefault()
    preview_div = $(this).parent().parent()
    preview_div.find(".hidden_preview").toggle()
    preview_div.find(".visible_preview").toggle()

  # alle nummervelden bij klik helemaal selecteren
  $("input[type=number]").click (e) ->
    $(this).select()

  $(".zeroify").click (e) ->
    $("input[type=number]").val("0")


  # bij klik op link in col-copy kolom
  # getal overzetten naar form in zelfde rij
  $(".col-copy").click (e) ->
    val = $(this).find("a").text()
    $(this).parent().find("input[type=number]").val(val)

  $("body").on "click", "a.disabled", (e) ->
    return false

  $(".toggle_demand").click (e) ->
    e.preventDefault()
    $("input[value=0]").closest("tr").toggle()

  $(document).on "change", "select[data-copy-paste-target]", (e)->
    value = $(e.target).val()
    id = $(e.target).data("copy-paste-target")
    $(id).val(value)

  $(".not-current-schoolyear-warning").appendTo "#page_title"
  
