# We gebruiken sweetalert2
# https://github.com/sweetalert2/sweetalert2
#
# Dit is niet gewoon versie 2, het is een kloon van sweetalert die niet volledig
# compatibel is.

`import Swal from "sweetalert2"`
window.swal = Swal.fire

document.addEventListener "turbo:load", ->
  $.fn.extend({
    render_lichtvis_alert: ->
      Swal.fire(
        html: $(this[0]),
        width: 800,
        showCancelButton: true,
        cancelButtonText: "Annuleer",
        confirmButtonText: "Oké",
      ).then (result) ->
        if result.value
          $("#swal2-html-container form").submit()
  })

  $(document).on "click", "a[data-form]", (e)->
  # $('a[data-form]').click (e)->
    e.preventDefault()

    button       = $(e.target)
    form_content = $(button.attr("data-form"))

    Swal.fire(
      html: form_content.html(),
      width: 800,
      showCancelButton: true,
      cancelButtonText: "Annuleer",
      confirmButtonText: "Oké",
    ).then (result) ->
      if result.value
        $("#swal2-html-container form").submit()
      else
        button.removeClass("disabled")
