`import BestInPlaceEditor from "vendor/best_in_place/best_in_place"`;

window.BestInPlaceEditor = BestInPlaceEditor;

document.addEventListener("turbo:load", function () {
  console.log("best_in_place was loaded");

  jQuery(".best_in_place").best_in_place();

  $(".best_in_place").on("ajax:success", function () {
    $(this).effect("highlight", {}, 1000);

    $(this)
      .append(
        '<i class="best_in_place_event fa fa-check" aria-hidden="true"></i>'
      )
      .find("i.best_in_place_event")
      .fadeOut(1000);
  });

  $(".best_in_place").on("best_in_place:error", function () {
    $(this).effect("highlight", { color: "#ff5b62" }, 1000);

    $(this)
      .append(
        '<i class="best_in_place_event fa fa-exclamation-triangle" aria-hidden="true"></i>'
      )
      .find("i.best_in_place_event")
      .fadeOut(1000);
  });
});

document.addEventListener("turbo:before-stream-render", (event) => {
  const fallbackToDefaultActions = event.detail.render;

  event.detail.render = async function (streamElement) {
    await fallbackToDefaultActions(streamElement);
    jQuery(".best_in_place").best_in_place();
  };
});

document.addEventListener("turbo:frame-render", function () {
  jQuery(".best_in_place").best_in_place();
});
