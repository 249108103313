document.addEventListener "turbo:load", ->
  focus_field_in_return_items_index = (element) ->
    $('.winkel_return_items ' + element).val("").focus()

  prepare_code = (input) ->
    chars = {"&":1, "é":2, "\"":3, "'":4, "(":5, "§": 6, "è":7, "!":8, "ç":9, "à":0}
    code = input.split("").map (char) ->
      if chars.hasOwnProperty(char) then chars[char] else char
    code.join("")

  scan_order_id = ->
    focus_field_in_return_items_index("#q_order_id_eq")

    $(".winkel_return_items #q_order_id_eq").keydown (e) ->
      if e.keyCode == $.ui.keyCode.ENTER or e.keyCode == $.ui.keyCode.TAB
        e.preventDefault()
        input = $(e.target).val()
        $(e.target).val(prepare_code(input))
        $("input:submit").val("Filter").click()
  scan_order_id()


  scan_return_item = ->
    $(".bulk_check_first_choice").click (e) ->
      e.preventDefault()
      scan_return_items_in_bulk()

    scan_return_items_in_bulk = ->
      for el in $("input[value=first_choice]")
        do ->
          $(el).prop("checked", true)
          $(el).trigger("change")


    change_status_tag = (return_item_id, code) ->
      color = if valid(return_item_id, code) then "green" else "orange"
      $(".status_tag."+return_item_id).hide()
      $(".status_tag."+return_item_id+"."+code).show().addClass(color)

    submit_batch_form = ->
      $(".batch_actions_selector a:contains('Scan')").click()

    init_audiocontext = (c)->
      if typeof AudioContext != "undefined"
        c.context = new AudioContext
      else
        c.context = false

    beep = (obj) ->
      return if !context

      if obj
        freq = 800
        type = "sine"

      o = context.createOscillator()

      o.type = type || "triangle"
      o.frequency.value = freq || 100

      o.connect(context.destination)
      o.start(context.currentTime)
      o.stop(context.currentTime + 0.2)

    valid = (return_item_id, code) ->
      scanned_choice_int = $("input.#{code}.#{return_item_id}").data("scanned_choice_int")
      initial_choice_int = $(".initial_choice.#{return_item_id}").data("initial_choice_int")

      return initial_choice_int <= scanned_choice_int

    $(".winkel_return_items .collection_selection").change (e) ->
      code = $(e.target).data("code")
      return_item_id = $(e.target).data("return_item_id")
      change_view code, return_item_id

    $(".winkel_return_items #article_code").keydown (e) ->

      if e.keyCode == $.ui.keyCode.ENTER or e.keyCode == $.ui.keyCode.TAB
        e.preventDefault()
        input = $(e.target).val()
        code = prepare_code(input)

        unless catch_special_codes code
          # change_view code
          reject_article code
        focus_field_in_return_items_index("#article_code")

    $(".winkel_return_items #save_scanned_items").click (e) ->
      e.preventDefault()
      submit_batch_form()

    $("select.states").change (e)->
      e.preventDefault()
      state = $(e.target).find(":selected").val()
      tr = $(e.target).closest("tr")

      if state == ""
        tr.find("input.collection_selection").prop("checked", false)
        tr.find("span.status_tag").hide()
      else
        tr.find("input.collection_selection.#{state}").prop("checked", true)
        tr.find("span.status_tag").hide()
        tr.find("span.status_tag.#{state}").show()

    catch_special_codes = (code) ->
      if code == "00000000"
        submit_batch_form()
        return true
      else if code == "11111111"
        scan_return_items_in_bulk()
        return true

    change_total = (number_of_checked_radios) ->
      $("#total_scanned").text(number_of_checked_radios)

    enable_submit_button = (number_of_checked_radios) ->
      if number_of_checked_radios > 0
        $("#save_scanned_items").removeClass("disabled")

    try_enable_submit_button = () ->
      number_of_checked_radios = $("input.collection_selection:checked").length
      change_total number_of_checked_radios
      enable_submit_button number_of_checked_radios

    reject_article = (code) ->
      return_item_id = $("input.#{code}").data("return_item_id")
      beep(return_item_id)

      $("select.states#states_#{return_item_id}").val("rejected")
      $("select.states#states_#{return_item_id}").trigger("change")

      try_enable_submit_button()

    change_view = (code, return_item_id) ->
      unless return_item_id
        return_item_id = $("input.#{code}").data("return_item_id")

      # beep(return_item_id)

      $("input.#{return_item_id}.#{code}").prop( "checked", true )
      change_status_tag(return_item_id, code)
      try_enable_submit_button()






    focus_field_in_return_items_index("#article_code")
    init_audiocontext(this)

  scan_return_item()
  $(".bulk_check_first_choice").trigger("click")
