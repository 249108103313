document.addEventListener "turbo:load", ->
  $(".row-soort input[type=radio]").on("change", ->
    $(".payment_forms > div").hide()

    form = $(".#{$(this).attr("id")}_form")
    form.toggle()

    title = $(this).parent().next("label").text()
    form.find("input#payment_title").val(title)

    transaction_type = $(this).attr("data-transaction-type")
    form.find("input#payment_transaction_type").val(transaction_type)

    kind = $(this).attr("data-kind")
    form.find("input#payment_kind").val(kind)

    granted_by = $(this).attr("data-granted-by")
    form.find("input#payment_granted_by").val(granted_by)
  )
