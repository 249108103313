// Load Active Admin's styles into Webpacker,
// see `active_admin.scss` for customization.
import "../stylesheets/active_admin";
import "@fortawesome/fontawesome-free/css/all.css";

import "custom-event-polyfill";

import "@hotwired/turbo-rails";

require("lib/turbo_loading_control");
require("lib/best_in_place");
require("lib/turbo_persist_scroll");

import "@activeadmin/activeadmin";

import "../active_admin/init";

// stimulus
import "controllers";

import "motion";

require("chartkick");
require("chartkick/chart.js");

require("trix");
require("@rails/actiontext");
